import React, { FC } from "react";
import { colors } from "~/utils/colors";
import { TelephoneNumberPanel } from "~/organisms/TelephoneNumberPanel";
import { DocumentDownloadButton } from "~/organisms/DocumentDownloadButton";
import { breakPoints } from "~/utils/variables";
import { MdCheck } from "@react-icons/all-files/md/MdCheck";
import { useLocation } from "@reach/router";

type DocumentGuide = {};

export const LpRpo01PageDocumentDownloadGuid: FC<DocumentGuide> = () => {
  const location = useLocation();
  const mdCheckIconStyle = {
    width: `32px`,
    height: `32px`,
    color: `${colors.white}`,
  };

  return (
    <>
      <div className="container">
        <div className="panelList">
          <div className="panel">
            <div className="checkIcon">
              <MdCheck style={mdCheckIconStyle} />
            </div>
            <span className="panelText">
              ウィルオブ・パートナーが中間に入るので
              <br />
              採用リソースの削減に
            </span>
          </div>
          <div className="panel">
            <div className="checkIcon">
              <MdCheck style={mdCheckIconStyle} />
            </div>
            <span className="panelText">
              成果報酬費が年収の20％で
              <br />
              転職エージェントより費用が低い
            </span>
          </div>
          <div className="panel">
            <div className="checkIcon">
              <MdCheck style={mdCheckIconStyle} />
            </div>
            <span className="panelText">
              社内メンバーからの紹介なので
              <br />
              面談からの決定率が高い
            </span>
          </div>
        </div>
        <span className="description">
          ウィルオブ・パートナーにお問い合わせいただいた方には
          <br />
          料金プランや利用開始時期を営業担当から順次ご案内いたします。
        </span>
        <div className="downloadSection">
          <DocumentDownloadButton to={`/lp/rpo/01${location.search}#contact`} />
          <TelephoneNumberPanel />
        </div>
      </div>

      <style jsx>
        {`
          .container {
            display: flex;
            flex-direction: column;
            align-items: center;
            background: ${colors.lightOrange};
            padding: 110px 20px 70px;
            gap: 40px;
          }
          .panelList {
            width: 1000px;
            display: flex;
            gap: 24px;
          }
          .panel {
            position: relative;
            width: 100%;
            height: 135px;
            background-color: ${colors.white};
            border-radius: 6px;
            border-right: 2px solid ${colors.orange};
            border-bottom: 2px solid ${colors.orange};
            padding: 40px 16px 16px 16px;
            text-align: center;
          }
          .checkIcon {
            position: absolute;
            top: -24px;
            left: 50%;
            transform: translateX(-50%);
            display: flex;
            align-items: center;
            justify-content: center;
            background: ${colors.brandGradient};
            width: 48px;
            height: 48px;
            border-radius: 50%;
          }
          .panelText {
            font-weight: bold;
            font-size: 18px;
            line-height: 18px;
            color: ${colors.darkText};
          }
          .description {
            color: ${colors.lightText};
            text-align: center;
            font-size: 20px;
          }
          .downloadSection {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            gap: 24px;
          }
          @media (min-width: 1000px) and (max-width: 1200px) {
            .panelList {
              width: 60%;
              flex-direction: column;
              gap: 42px;
            }
          }
          @media (${breakPoints.tablet}) {
            .panelList {
              width: 60%;
              flex-direction: column;
              gap: 42px;
            }
          }
          @media (${breakPoints.sp}) {
            .container {
              padding: 80px 20px;
            }
            .description {
              font-size: 16px;
            }
            .panelList {
              width: 100%;
              flex-direction: column;
              gap: 58px;
            }
            .panel {
              height: 88px;
              padding: 26px 16px 16px 16px;
            }
            .checkIcon {
              width: 40px;
              height: 40px;
            }
            .panelText {
              font-weight: normal;
              font-size: 14px;
            }
          }
        `}
      </style>
    </>
  );
};
