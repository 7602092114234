import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import { Container } from "~/atoms/Container";
import { Feature } from "~/atoms/IndexPageFeature";
import { breakPoints } from "~/utils/variables";
import { colors } from "~/utils/colors";

export const LpRpo01PageFeatures: React.FC<{}> = () => {
  const { feature1, feature2, feature3, feature4 } = useStaticQuery<GatsbyTypes.lpRpo01PageFeatureImageQuery>(graphql`
    query lpRpo01PageFeatureImage {
      feature1: file(relativePath: { eq: "feature1.png" }) {
        childImageSharp {
          gatsbyImageData(width: 600, quality: 100, placeholder: NONE, layout: CONSTRAINED)
        }
      }
      feature2: file(relativePath: { eq: "feature2.png" }) {
        childImageSharp {
          gatsbyImageData(width: 600, quality: 100, placeholder: NONE, layout: CONSTRAINED)
        }
      }
      feature3: file(relativePath: { eq: "feature3.png" }) {
        childImageSharp {
          gatsbyImageData(width: 600, quality: 100, placeholder: NONE, layout: CONSTRAINED)
        }
      }
      feature4: file(relativePath: { eq: "feature4.png" }) {
        childImageSharp {
          gatsbyImageData(width: 600, quality: 100, placeholder: NONE, layout: CONSTRAINED)
        }
      }
    }
  `);

  return (
    <>
      <style jsx>{`
        .whyChooseUsSession {
          content: "";
          display: block;
          height: 80px;
          margin-top: -80px;
          visibility: hidden;
        }
        .wrapper {
          padding: 80px 20px;
          position: relative;
          display: flex;
          justify-content: center;
          align-items: center;
        }
        .wrapper :global(.container) {
          flex-direction: column;
        }
        .sectionLabelWrapper {
          display: flex;
          justify-content: center;
        }
        .sectionLabel {
          color: ${colors.brand};
          font-size: 16px;
          font-weight: bold;
          letter-spacing: 1.75px;
        }
        .titleWrapper {
          padding: 0 24px;
          text-align: center;
        }
        .title {
          font-size: 32px;
          font-weight: bold;
          letter-spacing: 2px;
          background: radial-gradient(
              circle at calc(100% - 4px) calc(100% - 4px),
              ${colors.subOrange} 4px,
              transparent 4px
            ),
            radial-gradient(circle at 4px calc(100% - 4px), ${colors.subOrange} 4px, transparent 4px),
            linear-gradient(to bottom, ${colors.white} calc(100% - 8px), transparent calc(100% - 8px)),
            linear-gradient(
              to right,
              transparent 4px,
              ${colors.subOrange} 4px,
              ${colors.subOrange} calc(100% - 4px),
              transparent calc(100% - 4px)
            );
        }
        .featureTitle {
          font-size: 32px;
        }
        .triangle {
          position: absolute;
          top: 100%;
          width: 0;
          height: 0;
          border-style: solid;
          border-right: 120px solid transparent;
          border-left: 120px solid transparent;
          border-top: 50px solid ${colors.white};
          border-bottom: 0;
        }
        @media (${breakPoints.sp}) {
          .wrapper {
            padding: 50px 0 0;
          }
          .wrapper :global(.container) {
            padding: 0;
          }
          .titleWrapper {
            line-height: 36px;
          }
          .title {
            font-size: 28px;
            background: linear-gradient(transparent 70%, ${colors.subOrange} 50%);
          }
          .subTitle {
            display: none;
          }
          .features {
            margin-top: 30px;
          }
          .featureTitle {
            font-size: 24px;
          }
          .featureTitleBr {
            display: none;
          }
          .triangle {
            display: none;
          }
        }
        @media (${breakPoints.pc}) {
          .features :global(.wrapper:first-child) {
            margin: 50px 0 0;
          }
          .features :global(.wrapper:last-child) {
            margin: 0;
          }
        }
      `}</style>
      <div id="whyChooseUs" className="whyChooseUsSession" />
      <div className="wrapper">
        <Container>
          <div className="sectionLabelWrapper">
            <span className="sectionLabel">Solution</span>
          </div>
          <div className="titleWrapper">
            <span className="title">社内への展開から定着まで、すべてウィルオブ・パートナーが運用します</span>
            <br className="titleBr" />
            <span className="title subTitle">社内メンバーを転職エージェントに</span>
          </div>
          <div className="features">
            <Feature
              number="01"
              title={
                <>
                  <span className="featureTitle">
                    専属の担当者が
                    <br className="featureTitleBr" />
                    リファラル採用活動を運用
                  </span>
                </>
              }
              description="これまで850社以上のリファラル採用を支援してきた実績を基に、貴社の施策に合った企画・実行までを月30～50時間相当を運用します。"
              imageData={feature1?.childImageSharp?.gatsbyImageData}
            />
            <Feature
              number="02"
              title={
                <>
                  <span className="featureTitle">
                    社内オンボーディングの
                    <br className="featureTitleBr" />
                    徹底サポート
                  </span>
                </>
              }
              description="導入から定着まで、社内向け説明会の実施や、社内広報を目的とした記事・ポスター作製を行います。"
              imageData={feature2?.childImageSharp?.gatsbyImageData}
              direction="ltr"
            />
            <Feature
              number="03"
              title={
                <>
                  <span className="featureTitle">
                    リファラルを促進する
                    <br className="featureTitleBr" />
                    専用ツールのご提供
                  </span>
                </>
              }
              description="人事・社員・友人の連携の効率化やタレントプール機能によってリファラル採用を活性化するクラウドサービスが利用できます。"
              imageData={feature4?.childImageSharp?.gatsbyImageData}
            />
            <Feature
              number="04"
              title={
                <>
                  <span className="featureTitle">
                    初期費用と
                    <br className="featureTitleBr" />
                    固定費用が０円
                  </span>
                </>
              }
              description="完全成果報酬型なので、初期費用・固定費用・ツール費用がかかりません。導入のハードルやリスクを抑えて採用活動ができます。"
              imageData={feature3?.childImageSharp?.gatsbyImageData}
              direction="ltr"
            />
          </div>
        </Container>
        <div className="triangle" />
      </div>
    </>
  );
};
